import { missions, metiers, lots } from "./dossier.structure";
import { agencyCompanies, rejectReasonAssetFreeze } from "./dossiers";

const actionNames = Object.freeze({
  DEFAULT_CREATE: "default_create",
  DEFAULT_UPDATE: "default_update",
  DEFAULT_DELETE: "default_delete",

  INSURER_COMMUNICATION_RESPONSE: "insurer_communication_response",
  DOSSIER_OFFER_INSURER_COMMUNICATION_RESPONSE: "dossier_offer_insurer_communication_response",
  NEW_COMPANY_FILE: "new_company_file",
  NEW_DEVIS_FILE: "new_devis_file",
  NEW_ADDITIONAL_DEVIS_FILE: "new_additional_devis_file",
  NEW_INVOICE_FILE: "new_invoice_file",
  NEW_PROPOSITION_FILE: "new_proposition_file",
  NEW_CONTRACT_FILE: "new_contract_file",
  NEW_TEMPORARY_INSURANCE_FILE: "new_temporary_insurance_file",
  NEW_FILE: "new_file",
  DELETE_COMPANY_FILE: "delete_company_file",
  DELETE_DEVIS_FILE: "delete_devis_file",
  DELETE_ADDITIONAL_DEVIS_FILE: "delete_additional_devis_file",
  DELETE_INVOICE_FILE: "delete_invoice_file",
  DELETE_PROPOSITION_FILE: "delete_proposition_file",
  DELETE_CONTRACT_FILE: "delete_contract_file",
  DELETE_TEMPORARY_INSURANCE_FILE: "delete_temporary_insurance_file",
  DELETE_FILE: "delete_file",
  COMPANY_FILES_MERGED_TO_PDF: "company_files_merged_to_pdf",
  DEVIS_FILES_MERGED_TO_PDF: "devis_files_merged_to_pdf",
  ADDITIONAL_DEVIS_FILES_MERGED_TO_PDF: "additional_devis_files_merged_to_pdf",
  INVOICE_FILES_MERGED_TO_PDF: "invoice_files_merged_to_pdf",
  FILES_MERGED_TO_PDF: "files_merged_to_pdf",
  DEVIS_AMOUNT_UPDATED: "devis_amount_updated",
  DEVIS_LOTS_UPDATED: "devis_lots_updated",
  NEW_DEVIS: "new_devis",
  DELETE_DEVIS: "delete_devis",
  DEVIS_FILE_STATUS_UPDATED: "devis_file_status_updated",
  ADDITIONAL_DEVIS_AMOUNT_UPDATED: "additional_devis_amount_updated",
  ADDITIONAL_DEVIS_LOTS_UPDATED: "additional_devis_lots_updated",
  NEW_ADDITIONAL_DEVIS: "new_additional_devis",
  DELETE_ADDITIONAL_DEVIS: "delete_additional_devis",
  ADDITIONAL_DEVIS_FILE_STATUS_UPDATED: "additional_devis_file_status_updated",
  INVOICE_AMOUNT_UPDATED: "invoice_amount_updated",
  NEW_INVOICE: "new_invoice",
  DELETE_INVOICE: "delete_invoice",
  INVOICE_FILE_STATUS_UPDATED: "invoice_file_status_updated",
  NEW_COMMUNICATION_MESSAGE: "new_communication_message",
  TARIFS_SENT: "tarifs_sent",
  OFFER_REQUESTED: "offer_requested",
  OFFER_SENT: "offer_sent",
  OFFER_CONTACT: "offer_contact",
  OFFER_ACCEPTED: "offer_accepted",
  DOSSIER_NOTIFICATION_SENT: "dossier_notification_sent",
  ES_CIRCUIT_UPDATED: "es_circuit_updated",
  ES_CIRCUIT_FILES: "es_circuit_files",
  INSURER_COMMUNICATION_SENT: "insurer_communication_sent",
  DOSSIER_OFFER_INSURER_COMMUNICATION_SENT: "dossier_offer_insurer_communication_sent",
  SAVE_QUESTIONNAIRE: "save_questionnaire",
  RECEIVE_MAILJET_WEBHOOK_EVENT: "receive_mailjet_webhook_event",
  DOSSIER_DATE_END_SITE_NOTIFICATION: "dossier_date_end_site_notification",
  CLIENT_EMAIL_CHANGE: "client_email_change",
  DEVIS_METIERS_UPDATED: "devis_metiers_updated",
  DEVIS_MISSIONS_UPDATED: "devis_missions_updated",
  ADDITIONAL_DEVIS_MISSIONS_UPDATED: "additional_devis_missions_updated",
  ADDITIONAL_DEVIS_METIERS_UPDATED: "additional_devis_metiers_updated",
  DEVIS_COMMUNICATION_MESSAGE: "devis_communication_message",
  NEW_DOCUMENT_PROCES_FILE: "new_document_proces_file",
  DOCUMENT_PROCES_FILE_STATUS_UPDATED: "document_proces_file_status_updated",
  DELETE_DOCUMENT_PROCES_FILE: "delete_document_proces_file",
  DEVIS_COMMUNICATION_RESPONSE: "devis_communication_response",
  INSURER_COMMUNICATION_MESSAGE: "insurer_communication_message",

  REJECT_DOSSIER_ASSET_FREEZE: "reject_dossier_asset_freeze",
  CONTACT_RESET_PASSWORD: "contact_reset_password",
  TARIFS_SENT_DIRECT_CLIENT_NEW_DOSSIER: "tarifs_sent_direct_client_new_dossier",
  DEVIS_LOT_CHANGED: "devis_lot_changed",
  SHOW_PV_RESERVE: "show_pv_reserve",
  REASON_POSTPONEMENT: "reason_postponement",
  DATE_POSTPONEMENT: "date_postponement",
  DOCUMENT_PROCES_FILES_MERGED_TO_PDF: "document_proces_files_merged_to_pdf",
  NEW_SYNTHESIS_FILE: "new_synthesis_file",
  ERROR_SYNTHESIS_FILE: "error_synthesis_file",
  NEW_GEORISK_FILE: "new_georisk_file",
  ERROR_GEORISK_FILE: "error_georisk_file",
});

const notificationTypes = Object.freeze({
  EMAIL: "email",
  SMS: "sms",
});

const notificationLogReadStatuses = Object.freeze({
  UNREAD: "unread",
  READ: "read",
});

const NOTIFICATIONS_ITEMS_PER_PAGE = 50;

const translatedStatuses = {
  valid: "Validé(e)", // ???: "Validé(e)"?
  invalid: "Invalide",
  excluded: "Exclu",
  not_submitted: "Non soumis(e)", // ???: Non soumis(e)?
  in_validation: "Validation",
  pending: "Renseigner", // ???: "En attente"?
  unpaid: "Non payé",
  paid: "Payé",
  submitted: "Soumis",
};

const notificationMessages = Object.freeze({
  /** default.. */
  create: {
    dossier: "Le dossier a été créé",
    company: "L'entreprise a été créé",
    document: "Le document a été créé",
    insurer_communication: "La communication avec l'assureur a été créée",
    offer: "L'offre a été créée",
    es_circuit: "Le circuit a été créé",
  },
  update: {
    dossier: "Le dossier a été mis à jour",
    company: "L'entreprise a été mis à jour",
    document: "Le document a été mis à jour",
    insurer_communication: "La communication avec l'assureur a été mise à jour",
    offer: "L'offre a été mise à jour",
    es_circuit: "Le circuit a été mis à jour",
  },
  delete: {
    dossier: "Le dossier a été supprimé",
    company: "L'entreprise a été supprimé",
    document: "Le document a été supprimé",
    insurer_communication: "La communication avec l'assureur a été supprimée",
    offer: "L'offre a été supprimée",
    es_circuit: "Le circuit a été supprimé",
  },

  /** custom.. */
  [actionNames.NEW_COMPANY_FILE]: "Nouveau attestation d'assurance",
  [actionNames.NEW_DEVIS_FILE]: "Nouveau fichier devis",
  [actionNames.NEW_ADDITIONAL_DEVIS_FILE]: "Nouveau fichier devis complémentaire",
  [actionNames.NEW_INVOICE_FILE]: "Nouveau fichier facture",
  new_insurer_response_file: "Nouveau fichier de réponse de l'assureur",
  new_communication_file: "Nouveau fichier de communication",
  [actionNames.NEW_PROPOSITION_FILE]: "Nouveau fichier de proposition",
  [actionNames.NEW_CONTRACT_FILE]: "Nouveau fichier de contrat",
  [actionNames.NEW_TEMPORARY_INSURANCE_FILE]: "Nouveau fichier d'attestation prévisionnelle",
  [actionNames.NEW_FILE]: "Nouveau fichier",

  [actionNames.DELETE_COMPANY_FILE]: "Attestation d'assurance supprimé",
  [actionNames.DELETE_DEVIS_FILE]: "Fichier devis supprimé",
  [actionNames.DELETE_ADDITIONAL_DEVIS_FILE]: "Fichier devis complémentaire supprimé",
  [actionNames.DELETE_INVOICE_FILE]: "Fichier facture supprimé",
  delete_insurer_response_file: "Fichier de réponse de l'assureur supprimé",
  delete_communication_file: "Fichier de communication supprimé",
  [actionNames.DELETE_PROPOSITION_FILE]: "Fichier de proposition supprimé",
  [actionNames.DELETE_CONTRACT_FILE]: "Fichier de contrat supprimé",
  [actionNames.DELETE_TEMPORARY_INSURANCE_FILE]: "Fichier d'attestation prévisionnelle supprimé",
  [actionNames.DELETE_FILE]: "Fichier supprimé",

  [actionNames.COMPANY_FILES_MERGED_TO_PDF]:
    "Les fichiers attestation d'assurance fusionnent en PDF",
  [actionNames.DEVIS_FILES_MERGED_TO_PDF]: "Les fichiers devis fusionnent en PDF",
  [actionNames.ADDITIONAL_DEVIS_FILES_MERGED_TO_PDF]:
    "Les fichiers devis complémentaire fusionnent en PDF",
  [actionNames.INVOICE_FILES_MERGED_TO_PDF]: "Les fichiers facture fusionnent en PDF",
  [actionNames.FILES_MERGED_TO_PDF]: "Les fichiers fusionnent en PDF",

  invalidFileStatusReason: "Raison du statut invalide (fichier de devis)",
  invalidFileStatusComment: "Commentaire de statut non valide (fichier de devis)",

  [actionNames.DEVIS_AMOUNT_UPDATED]: "Montant devis mis à jour", // ???: + amount
  [actionNames.NEW_DEVIS]: "Nouveau devis",
  [actionNames.DELETE_DEVIS]: "Devis supprimé",
  [actionNames.DEVIS_FILE_STATUS_UPDATED]: {
    [actionNames.DEVIS_FILE_STATUS_UPDATED]: "Statut du fichier de devis",
    ...translatedStatuses,
  },
  [actionNames.DEVIS_LOTS_UPDATED]: {
    [actionNames.DEVIS_LOTS_UPDATED]: "Devis lots réalisés",

    ...Object.values(lots)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },

  [actionNames.ADDITIONAL_DEVIS_AMOUNT_UPDATED]: "Montant devis complémentaire mis à jour", // ???: + amount
  [actionNames.NEW_ADDITIONAL_DEVIS]: "Nouveau devis complémentaire",
  [actionNames.DELETE_ADDITIONAL_DEVIS]: "Devis complémentaire supprimé",
  [actionNames.ADDITIONAL_DEVIS_FILE_STATUS_UPDATED]: {
    [actionNames.ADDITIONAL_DEVIS_FILE_STATUS_UPDATED]: "Statut du fichier de devis complémentaire",
    ...translatedStatuses,
  },
  [actionNames.ADDITIONAL_DEVIS_LOTS_UPDATED]: {
    [actionNames.ADDITIONAL_DEVIS_LOTS_UPDATED]: "Devis complémentaire lots réalisés",

    ...Object.values(lots)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },

  [actionNames.INVOICE_AMOUNT_UPDATED]: "Montant de la facture mis à jour", // ???: + amount
  [actionNames.NEW_INVOICE]: "Nouvelle facture",
  [actionNames.DELETE_INVOICE]: "Facture supprimée",
  [actionNames.INVOICE_FILE_STATUS_UPDATED]: {
    [actionNames.INVOICE_FILE_STATUS_UPDATED]: "Statut du fichier de facture",
    ...translatedStatuses,
  },

  [actionNames.NEW_COMMUNICATION_MESSAGE]: "Nouveau message",

  [actionNames.TARIFS_SENT]: "Les tarifs ont été envoyés au client",
  [actionNames.OFFER_REQUESTED]: "Le client a demandé une offre détaillée",
  [actionNames.OFFER_ACCEPTED]: "Une offre a été acceptée",
  [actionNames.OFFER_SENT]: "Une offre détaillée a été envoyée au client",
  [actionNames.OFFER_CONTACT]: "Le client a demandé à être recontacté",

  [actionNames.INSURER_COMMUNICATION_SENT]: "Message de communication de l'assureur envoyé",
  [actionNames.INSURER_COMMUNICATION_RESPONSE]: "Réponse de communication de l'assureur",
  [actionNames.DOSSIER_OFFER_INSURER_COMMUNICATION_SENT]:
    "Message de communication de l'assureur envoyé",
  [actionNames.DOSSIER_OFFER_INSURER_COMMUNICATION_RESPONSE]:
    "Réponse de communication de l'assureur",

  [actionNames.ES_CIRCUIT_FILES]: "Fichiers traités (EDI Signature)",

  [actionNames.SAVE_QUESTIONNAIRE]: "Le questionnaire a été rempli",

  // independent
  [actionNames.RECEIVE_MAILJET_WEBHOOK_EVENT]: "Infos e-mail Mailjet",
  [actionNames.DOSSIER_DATE_END_SITE_NOTIFICATION]: "Fin prévue de chantier est proche",
  [actionNames.DEVIS_COMMUNICATION_MESSAGE]: "Infos e-mail Communication Client",

  [actionNames.DOSSIER_NOTIFICATION_SENT]: "Notification de dossier envoyée",
  [actionNames.CLIENT_EMAIL_CHANGE]: "Le client demande le changement d'adresse e-mail",

  [actionNames.NEW_DOCUMENT_PROCES_FILE]: "Nouveau fichier PV",
  [actionNames.DELETE_DOCUMENT_PROCES_FILE]: "Fichier PV supprimé",
  [actionNames.DOCUMENT_PROCES_FILE_STATUS_UPDATED]: {
    [actionNames.DOCUMENT_PROCES_FILE_STATUS_UPDATED]: "Statut du fichier PV",
    ...translatedStatuses,
  },

  [actionNames.DEVIS_METIERS_UPDATED]: {
    [actionNames.DEVIS_METIERS_UPDATED]: "Devis lot",

    ...Object.values(metiers)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },
  [actionNames.DEVIS_MISSIONS_UPDATED]: {
    [actionNames.DEVIS_MISSIONS_UPDATED]: "Devis mission réalisée",

    ...Object.values(missions)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },

  [actionNames.ADDITIONAL_DEVIS_MISSIONS_UPDATED]: {
    [actionNames.ADDITIONAL_DEVIS_MISSIONS_UPDATED]: "Devis complémentaire mission réalisée",

    ...Object.values(missions)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },
  [actionNames.ADDITIONAL_DEVIS_METIERS_UPDATED]: {
    [actionNames.ADDITIONAL_DEVIS_METIERS_UPDATED]: "Devis complémentaire lot",

    ...Object.values(metiers)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },
  [actionNames.DEVIS_COMMUNICATION_RESPONSE]: "Réponse de communication client",
  [actionNames.INSURER_COMMUNICATION_MESSAGE]: "Infos e-mail  Communication Assureur",

  [actionNames.REJECT_DOSSIER_ASSET_FREEZE]: rejectReasonAssetFreeze,

  [actionNames.CONTACT_RESET_PASSWORD]:
    "Le client a demandé à être contacté pour résoudre les problèmes de connexion",
  [actionNames.TARIFS_SENT_DIRECT_CLIENT_NEW_DOSSIER]: "Les tarifs ont été envoyés au client",
  [actionNames.OFFER_DEMAND]: "Le client a demandé une offre",
  [actionNames.DEVIS_LOT_CHANGED]: "Le devis initiale ou les lots ont été modifiés",
  [actionNames.SHOW_PV_RESERVE]: "Le Procès verbaux de levée de réserve été ajouté ou supprimé",
  [actionNames.REASON_POSTPONEMENT]: "Motif(s) du report de la réception du chantier",
  [actionNames.DATE_POSTPONEMENT]: "Date de réception prévisionnelle reportée",
  [actionNames.DOCUMENT_PROCES_FILES_MERGED_TO_PDF]: "Les fichiers PV fusionnent en PDF",
  [actionNames.NEW_SYNTHESIS_FILE]: "Nouveau fichier de Kbis",
  [actionNames.ERROR_SYNTHESIS_FILE]: "Le fichier de synthèse n'a pas pu être téléchargé",
  [actionNames.NEW_GEORISK_FILE]: "Nouveau fichier de géorisque",
  [actionNames.ERROR_GEORISK_FILE]: "Le fichier de géorisque n'a pas pu être téléchargé",
});

const dossierNotificationTypes = Object.freeze({
  offer_sent: "Offre DO reçue",
  offer_sent_reminder: "Offre DO reçue (relance)",
  offer_accepted: "Offre acceptée",
  offer_accepted_reminder: "Offre acceptée (relance)",
  questionnaire_submitted: "AR Dossier",
  missing_docs_first_time: "AR Dossier traité",
  missing_docs_first_time_reminder: "Relance pièces manquantes",
  missing_docs: "AR Pièces manquantes",
  send_docs_to_insurer: "AR Dossier complet",
  valid_answer_from_insurer: "AR Dossier validé",
  appel_prime_payment_reminder: "AR Dossier validé (rappel)",
  phase_6_validated: "Envoi Attestation",
  phase_6_validated_reminder: "Demande AVIS",
  dossier_created: "Offres complémentaires",
  dossier_created_reminder: "Offres complémentaires",
  dossier_date_end_site: "Dossier à réceptionner",
  phase_5_validated_last_moment: "Dossier est réceptionné",
  missing_docs_first_time_reminder_reception: "Relance pièces manquantes",
  appel_prime_payment_reminder_reception: "AR Dossier validé (rappel)",
  dossier_created_reception: "Offres complémentaires",
  phase_6_validated_reminder_reception: "Demande AVIS",
  dossier_responsible_reception_assigned: "Affection gestionnaire réception",
  final_certificate_validated: "Attestation definitive validé",
  send_docs_reception_to_insurer: "AR Dossier complet",
  validation_reception: "Validation définitive par l’assureur validé",

  offre_emprunteur: "Offre complémentaire prêt",
  offre_emprunteur_phase_8: "Offre complémentaire prêt",
  offre_client_mrh_pj: "Offre Protection Juridique",
  offre_client_mrh_pj_cron: "Offre Protection Juridique",
  offre_client_mrh_pjgpa: "Offre GPA Protection Juridique",
  offre_client_mrh: "Offre Habitation AZ",
  offre_client_mrh_gli: "Offre Habitation AXA",
  offre_client_mrh_reno: "Offre Habitation Rénovation",
  dossier_created_professional: "Présentation Agence PAULIN - Nos offres aux professionnels",
  dossier_created_particular: "Présentation Agence PAULIN - Nos offres aux particuliers",
});

const defaultUpdateKeyValueMessages = Object.freeze({
  agencyCompany: {
    agencyCompany: "Société d'agence",

    ...Object.values(agencyCompanies)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },

  status: {
    status: "Statut",
    ...translatedStatuses,
  },
  invalidStatusReason: "Raison du statut invalide",
  invalidStatusComment: "Commentaire de statut non valide",
  locked: {
    locked: "Fermé à clé",

    true: "Vrai",
    false: "Faux",
  },
  active: {
    active: "Actif",

    true: "Vrai",
    false: "Faux",
  },

  "rowData.documentName": "Nom du document",

  "rowData.automaticQuestionnaire": {
    "rowData.automaticQuestionnaire": "Questionnaire automatique",

    true: "Vrai",
    false: "Faux",
  },

  "rowData.rule": "Motif",

  "rowData.company.name": "Nom de l'entreprise",
  "rowData.company.siren": "Siren",
  "rowData.company.address": "Adresse",
  "rowData.company.email": "Email",
  "rowData.company.phone": "Téléphone",
  "rowData.company.insurerName": "Assureur",
  "rowData.company.contractNumber": "N° Contrat",

  "rowData.company.fileStatus": {
    "rowData.company.fileStatus": "Statut du attestation d'assurance",
    ...translatedStatuses,
  },
  "rowData.company.invalidFileStatusReason": "Raison du statut invalide (Attestation d'assurance)",
  "rowData.company.invalidFileStatusComment":
    "Commentaire de statut non valide (Attestation d'assurance)",
  "rowData.fileStatus": {
    "rowData.fileStatus": "Statut de fichiers (Contrat / Rapport)",
    ...translatedStatuses,
  },
  "rowData.contractFinal.fileStatus": {
    "rowData.contractFinal.fileStatus": "Statut de fichiers (Contrat / Rapport final)",
    ...translatedStatuses,
  },
  "rowData.invalidFileStatusReason": "Raison du statut invalide (Contrat / Rapport)",
  "rowData.invalidFileStatusComment": "Commentaire de statut non valide (Contrat / Rapport)",

  "rowData.company.certificateStartDate": "Attestation Date Début",
  "rowData.company.certificateEndDate": "Attestation Date Fin",

  "rowData.missions": {
    "rowData.missions": "Missions obligatoires",

    ...Object.values(missions)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },
  "rowData.metiers": {
    "rowData.metiers": "Lot",

    ...Object.values(metiers)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },
  "rowData.lots": {
    "rowData.lots": "Lots réalisés",

    ...Object.values(lots)
      .map(val => ({ [val.key]: val.name }))
      .reduce((previousValue, currentValue) => ({ ...previousValue, ...currentValue }), {}),
  },

  "rowData.step1Passed": {
    "rowData.step1Passed": "Documents vérifiés",

    true: "Vrai",
    false: "Faux",
  },
  "rowData.step2Passed": {
    "rowData.step2Passed": "A obtenu une réponse de l'assureur",

    true: "Vrai",
    false: "Faux",
  },
  sendDocuments: {
    sendDocuments: "Envoyer les documents",

    true: "Demandé",
    false: "Envoyé",
  },
  sentDate: "Date d'envoi",
  "rowData.insurerResponseMessage": "Message de réponse de l'assureur",

  "rowData.sameFinalInvoice": {
    "rowData.sameFinalInvoice": "Facture finale identique",

    true: "Vrai",
    false: "Faux",
  },

  "rowData.check": {
    "rowData.check": "Chèque",

    true: "Vrai",
    false: "Faux",
  },

  "rowData.note": "Commentaire",
});

const userActions = Object.freeze({
  OFFER_SENT_TO_INSURER: "offer_sent_to_insurer",
});

const mailjetTranslatedStatuses = {
  bounce: "Rebondir",
  spam: "Spam",
  blocked: "Bloqué",
};

const notificationActionTypes = Object.freeze({
  receive_mailjet_webhook_event: {
    key: "receive_mailjet_webhook_event",
    name: "Mailjet info",
  },
  devis_communication_response: {
    key: "devis_communication_response",
    name: "Communication Client",
  },
  insurer_communication_message: {
    key: "insurer_communication_message",
    name: " Communication Assureur",
  },
  tarifs_sent_direct_client_new_dossier: {
    key: "tarifs_sent_direct_client_new_dossier",
    name: "Nouvelle subscription",
  },
});

export {
  actionNames,
  notificationTypes,
  notificationLogReadStatuses,
  NOTIFICATIONS_ITEMS_PER_PAGE,
  notificationMessages,
  defaultUpdateKeyValueMessages,
  userActions,
  mailjetTranslatedStatuses,
  notificationActionTypes,
  dossierNotificationTypes,
};
